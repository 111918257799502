import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const RealEstatePage = ({ data }) => {
    const { html, frontmatter, fields } = data.file.childMarkdownRemark;
    const { criteria, advantages } = fields;

    return (
        <Layout>
            <SEO title="Real Estate"/>
            <section className="hero is-light is-medium is-banner real-estate">
                <div className="background-image"/>
            </section>
            <section className="section">
                <div className="container">
                    <h1 className="title">{frontmatter.headline}</h1>
                    <div className="content" dangerouslySetInnerHTML={{ __html: html }}/>
                    <hr className="hr"/>
                    <h2 className="title is-size-4">Acquisition Criteria</h2>
                    {
                        criteria.map(c => {
                            return (
                                <div className="content">
                                    <h3 className="is-size-5">{c.name}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: c.html }}/>
                                </div>
                            );
                        })
                    }
                    <hr className="hr"/>
                    <h2 className="title is-size-4">{advantages.name}</h2>
                    <div className="content" dangerouslySetInnerHTML={{ __html: advantages.html }}/>
                </div>
            </section>
        </Layout>
    );
}

export const query = graphql`
  query RealEstateQuery {
    file(name: {eq: "real-estate"}) {
      childMarkdownRemark {
        frontmatter {
          headline
        }
        fields {
            criteria {
                name
                html
            }
            advantages {
                name
                html
            }
        }
        html
      }
    }
  }
`

export default RealEstatePage